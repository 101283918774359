// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "channels"
require("../stylesheets/application.scss")
require.context('../images', true)

window.bootstrap = require("bootstrap");
Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('turbolinks:load', () => {
  const showMeIdeasLink = document.getElementById('show-me-decision-ideas');
  const hiddenDecisionIdeas = document.getElementById('decision-ideas');

  showMeIdeasLink?.addEventListener("click", () => {
    if (!!hiddenDecisionIdeas.getAttribute('hidden')) {
      hiddenDecisionIdeas.removeAttribute('hidden');
    }
  });

  const showMeResultsLink = document.getElementById('show-me-decision-results');
  const hiddenDecisionResults = document.getElementById('decision-results');

  showMeResultsLink?.addEventListener("click", () => {
    if (!!hiddenDecisionResults.getAttribute('hidden')) {
      hiddenDecisionResults.removeAttribute('hidden');
    }
  });

  const decisionNameInput = document.getElementById('decision_name');
  const createDecisionButton = document.getElementById('create-decision-button');

  decisionNameInput?.addEventListener(('keyup'), () => {
    if (decisionNameInput.value !== '') {
      createDecisionButton.disabled = false;
    }
  });

  const factorInputBox = document.getElementById('factor_name');
  const nextButtonFactorsPage = document.getElementById('next-button-factors-page');
  nextButtonFactorsPage?.addEventListener(('click'), (e) => {
    if (factorInputBox.value.trim().length > 0) {
      e.preventDefault();
      e.stopPropagation();
      const link = nextButtonFactorsPage.getAttribute('href');
      const result = confirm('You have unsaved changes. Do you want to continue anyway?');
      if (result) {
        window.location = link;
        return true;
      } else {
        return false;
      }
    }
  });

  const optionInputBox = document.getElementById('option_name');
  const nextButtonOptionsPage = document.getElementById('next-button-options-page');
  nextButtonOptionsPage?.addEventListener(('click'), (e) => {
    if (optionInputBox.value.trim().length > 0) {
      e.preventDefault();
      e.stopPropagation();
      const link = nextButtonOptionsPage.getAttribute('href');
      const result = confirm('You have unsaved changes. Do you want to continue anyway?');
      if (result) {
        window.location = link;
        return true;
      } else {
        return false;
      }
    }
  })

  const decisionIdeaLinks = document.querySelectorAll('.use-this-decision-link');
  if (decisionIdeaLinks.length && decisionNameInput && createDecisionButton) {
    for (let i = 0; i < decisionIdeaLinks.length; i++) {
      const link = decisionIdeaLinks[i];
      link.addEventListener(('click'), () => {
        const textToPrefill = link.parentElement.textContent.split(' Use')[0];
        decisionNameInput.value = textToPrefill;
        createDecisionButton.disabled = false;
        createDecisionButton.click();
      })
    }
  }
});